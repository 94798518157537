<template>
    <div id="permission-modal">
        <Smallmodal
        :modalShow="showPremissionsModal"
        @closeSmallModal="closePremissionsModal()"
        >
      <div id="premissions-modal-container">
        <h2>{{$t('permissions.heading')}}</h2> 
        <br/><br/>
        <table>
          <tr>
            <th class="text-right">&nbsp;</th>
            <th>{{$t('global.adult')}}</th>
            <th>{{$t('global.kid')}}</th>           
          </tr>
          <tr> 
            <td class="text-right permission-label">{{$t('permissions.account')}}</td>
            <td><img src="/img/btns/permission_yes.svg" width="40" height="40" :alt="$t('global.yes')" :title="$t('global.yes')"/></td>
            <td><img src="/img/btns/permission_no.svg" width="34" height="34" :alt="$t('global.no')" :title="$t('global.no')"/></td>
          </tr>
          <tr> 
            <td class="text-right permission-label">{{$t('permissions.invites')}}</td>
            <td><img src="/img/btns/permission_yes.svg" width="40" height="40" :alt="$t('global.yes')" :title="$t('global.yes')"/></td>
            <td><img src="/img/btns/permission_no.svg" width="34" height="34" :alt="$t('global.no')" :title="$t('global.no')"/></td>
          </tr>
          <tr> 
            <td class="text-right permission-label">{{$t('permissions.profiles')}}</td>
            <td><img src="/img/btns/permission_yes.svg" width="40" height="40" :alt="$t('global.yes')" :title="$t('global.yes')"/></td>
            <td><img src="/img/btns/permission_no.svg" width="34" height="34" :alt="$t('global.no')" :title="$t('global.no')"/></td>
          </tr>    
          <tr> 
            <td class="text-right permission-label">{{$t('permissions.switch')}}</td>
            <td><img src="/img/btns/permission_yes.svg" width="40" height="40" :alt="$t('global.yes')" :title="$t('global.yes')"/></td>
            <td><img src="/img/btns/permission_no.svg" width="34" height="34" :alt="$t('global.no')" :title="$t('global.no')"/></td>
          </tr>
          <tr> 
            <td class="text-right permission-label">{{$t('permissions.signout')}}</td>
            <td><img src="/img/btns/permission_yes.svg" width="40" height="40" :alt="$t('global.yes')" :title="$t('global.yes')"/></td>
            <td><img src="/img/btns/permission_no.svg" width="34" height="34" :alt="$t('global.no')" :title="$t('global.no')"/></td>
          </tr>                                    
        </table>
      </div>
        </Smallmodal>
    </div>  
</template>
<script>

import Smallmodal from '@/components/modals/smallmodal.vue'
export default {
  name: "Permissions", 
  components: {
    Smallmodal
  },
  props: {
    showPremissionsModal: Boolean
  },
  watch: {
      'showPlayerleft': {
          handler (newVal) {
              if (newVal && this.showPremissionsModal == true) { 
                  this.tracking({
                      type: 'event',
                      category: 'activity:nav',
                      action: 'popup:view',
                      label: 'profilepermissions'
                  });
              }
          },
          immediate: true 
      }
  },     
  data() {
    return {
        error: null
    };
  },
  methods: {
    closePremissionsModal(){
      this.$emit('closePremissionsModal');    
      this.tracking({
          type: 'event',
          category: 'activity:nav',
          action: 'popup:close',
          label: 'profilepermissions'
      });          
    }     
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';
#premissions-modal-container {
  margin: 30px 0;
  .permission-label {
    padding: 0 10px 0 0;
  }
  table {
    tr {
      height: 30px;
    }
    th {
      font-size: $h3-size;
      line-height: $h3-lh;
      padding: 0 10px 0 0;      
    }
    td {
      font-size: $h3-size;
      line-height: $h3-lh;
      padding: 0 10px 0 0;
    }
  }
}
</style>