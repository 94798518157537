<template>
<div id="support-modal-overlay" @click="closeModal()" v-if="showModal == true"> <!-- @click="closeSettingsReauthModal()" -->
    <div id="support-modal-container" >
        <div id="support-modal" class="ps-modal-box">
            <div>
            <ul>
                <li>
                    <a @click.prevent="sendtoFAQ()">{{$t('nav.faq')}} <div class="linkout-icon-purple ps-icons-nav"></div></a>
                </li>   
                <li>
                    <a @click.prevent="supportEmail()">{{$t('nav.support')}} <div class="linkout-icon-purple ps-icons-nav"></div></a>
                </li>   
            </ul>
            </div>  
        </div>         
    </div>
</div>    
</template>
<script>

export default {
  name: "SupportModal", 
  props: {
    showModal: Boolean
  },
  watch: {
      'showModal': {
          handler (newVal) {
              if (newVal && this.showPWA == true) { 
                  this.tracking({
                    type: 'event',
                    category: 'register-flow',
                    action:  'support:view',
                  });
              }
          },
          immediate: true 
      }
    },   
  data() {
    return {
        error: null
    };
  },
  methods: {
    closeModal(){
      this.$emit('closeModal');        
    },
    supportEmail(){
      let url;
      url = 'mailto:support@playspaces.co';
      url += '?subject=Playspaces Beta Help';      
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action:  'support:email',
      }); 
      window.open(url,'_blank');  
    },
    sendtoFAQ(){
      this.tracking({
        type: 'event',
        category: 'register-flow',
        action:  'support:faq',
      }); 
      window.open('https://www.playspaces.co/faq','_blank');  
    },         
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';
#support-modal-overlay {

}
#support-modal-container {
    position: absolute;
    top: -55px;
    right: 11px;
    z-index: 5600;   
}
#support-modal::after {
    bottom: 100%;
    left: 0px;
    border: solid transparent;
    transform: rotate(180deg);
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: $ps-light-purple;
    border-width: 18px;
    margin-left: 63.5%;
}
#support-modal {
  ul {
    list-style-type: none; 
    padding: 0;
    margin: 0; 
  }
  li {
    font-size: 14px;
    font-weight: 600;
    a{
      font-size: 14px;
    }
    margin: 10px 0 15px 0;
  }
}
#support::before {
    content: '';
    bottom: 100%;
    left: 0px;
    margin-left: 50%;
    margin-bottom: -27px;
    position: absolute;
    transform: rotate(135deg);
    width: 36px;
    height: 36px;
    z-index: -1;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
#support-modal {
    background: $ps-light-purple;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    width: 300px;
    height: 100%;
    padding: 30px;
    color: $ps-black;
}
</style>