<template>
  <div id="general-modal-container" v-show="modalShow" v-on:click.self="closeModal()">
    <div class="general-modal">
        <div id="smallmodal-close" @click.prevent="closeModal()">
            <img src="/img/btns/close.svg" width="22" height="22"/>
        </div>       
        <slot/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Smallmodal",
    data() {
      return {
        
      }
    },
    props: {
      modalShow: Boolean
    },    
    methods: {
      closeModal(){
        this.$emit('closeSmallModal');
      },
      doneModalAction(){
        this.$emit('doneModalAction');

      },
      cancelModalAction(){
        this.$emit('cancelModalAction');
      }
    },
    mounted(){
      
    }
  }
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#general-modal-container {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 8100;
  width: 100%;
  height: 100%;
  background: rgba(38, 32, 114, 0.5);
}
#smallmodal-close {
  position: absolute;
  top: 20px;
  right: 30px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 7100;
  padding: 9px 0;
}
.circle-btn-cancel, .circle-btn-done {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  img {
    text-align: center;
    margin: 15px auto 0 auto;
  } 
}
.circle-btn-cancel {
  background: $ps-white;
  border: 1px solid $ps-purple;
  
}
.circle-btn-done {
  background: $ps-purple;
}

</style>