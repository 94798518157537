<template>
  <div id="support-link">
    <Support
    :showModal="show.support"
    @closeModal="closeSupportModal()"
    />          
    <div id="support" @click="toggleSupport()">
      <div class="d-inline-block top-align">
        <img src="/img/btns/help_btn.svg" width="24" height="24" />
      </div>
      <div class="support-text d-inline-block top-align">
        {{$t('global.support')}}
      </div>
    </div>
  </div>
</template>

<script>
import Support from '@/components/modals/support.vue'
export default {
  name: "supportlink",
  components: {
    Support
  }, 
  data() {
    return {
      show: {
        support: false
      } 
    }
  },       
  methods: {
    trackLink(name){
      this.tracking({
          type: 'event',
          category: 'footer',
          action: 'external_link',
          label: name
      }); 
    },
    closeSupportModal(){
      this.show.support = false;
    },
    toggleSupport(){
      if(this.show.support === true){
        this.show.support = false;
      } else {
        this.show.support = true;
      }
    } 
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#support-link {

}
#support{
  position: absolute;
  width: 100px;
  top: -99px;
  right: 15px;
  z-index: 901;
  color: $ps-white;  
  font-size: $btn-font-size;
  cursor: pointer;
  .support-text {
    margin: 0 0 0 10px;
    padding: 3px 0 0 0;
  }
}
</style>
