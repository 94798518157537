<template>
<div id="auth-container">
  <Permissions
  :showPremissionsModal="show.permissions"
  @closePremissionsModal="closePremissionsModal()"
  />
     
  <div id="room-background">
    <div id="logo-pos">
      <h1 class="playspace-logo">{{$t('global.app_name')}}</h1>
    </div>
    <div id="auth-modal-container">   
        <div id="auth-modal" class="general-modal">
          <Supportlink/>
          <div id="auth-friends">
            <img src="/img/elements/auth/auth_friends.svg" width="175" height="138" />
          </div>        
          <slot /> 
        </div>
    </div>
  </div>
</div>
</template>

<script>
import Permissions from '@/components/modals/permissions.vue'
import Supportlink from '@/components/supportlink.vue'
// this is used for login/logout and the account creation flow
export default {
  name: "authlayout",
  components: {
    Permissions,
    Supportlink
  },  
  data() {
    return {
      show: {
        permissions: false,
        support: false
      } 
    }
  },  
  mounted() {
    this.$on('openPermissionsModal', () => {
      this.show.permissions = true;      
    })
  },
  methods: {
    closePremissionsModal(){
      this.show.permissions = false;
    },  
 
  }
};
</script>

<style lang="scss" >
@import '@/assets/scss/variables';
//TODO this is broken for mobile devices.
#auth-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 768px;
  z-index: 150;
  /*overflow: scroll;*/
}
#auth-modal-container {
  width: 100%;
  height: 100%;
}

#auth-modal {
  text-align: left;
  position: relative;
  z-index: 100;
  padding: 60px 70px;
  width: 83.984%;
  min-height: 72.916%; 
  min-width: 320px;
  max-width: 860px;
}

#auth-friends{
  position: absolute;
  width: 130px;
  top: -115px;
  left: 11%;
  z-index: 900;
}
.button-right {
  padding: 13px 0 0 40px;
  height: 40px;
}
</style>